<template>
  <div
    v-if="festival != undefined"
    class="frame-festival"
    @click="$emit('afficher-details-festival')"
  >
    <div class="frame-titre">
      <div class="frame-date">
        <p class="date-festival">
          {{
            festival.date_festival
              .split("-")
              .reverse()
              .join("/")
          }}
        </p>
      </div>
      <div class="frame-nom">
        <p class="nom-festival">{{ festival.nom_festival }}</p>
      </div>
    </div>
    <div class="frame-details">
      <div class="frame-lieu">
        <img
          alt=""
          class="place-marker"
          src="assets/icones/icone-festival-lieu.png"
        />
        <p class="lieu-festival">{{ festival.lieu_festival }}</p>
      </div>
      <div class="frame-lieu">
        <img alt="" class="verified-badge" :src="iconeCloture" />
        <p class="lieu-festival">{{ clotureFestival }}</p>
      </div>
      <div class="frame-lieu" title="Ventes festival">
        <img
          alt=""
          class="verified-badge"
          src="assets/icones/icone-festival-ventes.png"
        />
        <p class="lieu-festival">{{ ventesFestival }}</p>
      </div>
      <div
        v-if="user.adminAccessGranted"
        class="frame-supprimer element-clickable"
        @click.stop="$emit('supprimer-festival')"
      >
        <img
          src="assets/icones/iconeSupprimer.png"
          alt="supprimer"
          class="verified-badge"
        />
        <div class="lieu_festival">Supprimer</div>
      </div>
    </div>
  </div>
  <div v-else class="frame-festival" @click="() => $emit('ajouter-festival')">
    <div class="frame-ajouter-festival">
      <div class="texte-ajouter">Ajouter un festival</div>
      <img src="assets/icones/icone-festival-ajouter.png" alt="Ajouter" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { consoleMessage } from "../js/consoleMessage";
export default {
  name: "ComposantCarteFestival",
  props: {
    festival: { date_festival: "" },
  },
  computed: {
    ...mapState(["user"]),
    clotureFestival() {
      return this.festival.cloture_festival == 0 ? "Non clôturé" : "Clôturé";
    },
    iconeCloture() {
      return this.festival.cloture_festival == 1
        ? "assets/icones/icone-festival-cloture.png"
        : "assets/icones/icone-festival-encours.png";
    },
    ventesFestival() {
      return this.festival.ventesFestival == undefined
        ? "?"
        : this.festival.ventesFestival + " vendus";
    },
  },
  mounted: function() {
    consoleMessage("this.festival", this.festival);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.frame-festival {
  background-color: $fond-page;
  border-radius: 11px;
  padding: 13px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 0;
  transition-property: all;
  transition-duration: 100ms;
  cursor: pointer;
  &:hover {
    bottom: 5px;
  }
  width: 280px;
  height: 270px;
}
.frame-titre {
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.frame-date {
  margin-bottom: 8px;
  overflow: hidden;
  // border-radius: 35px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.date-festival {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: $couleur-texte-fonce;
  text-align: center;
}
.frame-nom {
  overflow: hidden;
  // border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.nom-festival {
  color: $couleur-texte-fonce;
  align-self: stretch;
  text-align: center;
  @include typo-large;
}
.frame-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120px;
}
.frame-lieu {
  overflow: hidden;
  // border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}
.place-marker {
  width: 31px;
  height: 31px;
  margin-right: 7px;
}
.lieu-festival {
  color: $couleur-texte-fonce;
  white-space: nowrap;
  @include typo-medium;
}
.verified-badge {
  width: 30px;
  height: 30px;
  margin-right: 7px;
}

.frame-ajouter-festival {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  justify-content: space-between;
  align-items: center;

  & img {
    margin-top: 20px;
    width: 60px;
  }
}

.frame-supprimer {
  @extend .frame-lieu;
  margin-bottom: 10px;
}
.icone-supprimer {
  @extend .element-clickable;
  margin-top: 10px;
}
</style>
